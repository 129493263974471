import React from "react";
import "./ListingPage.css";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import ListContent from "../../../components/Listing/ListContent/ListContent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const ListingPage = () => {
  const { t } = useTranslation();
  const sidebarActive = useSelector(
    (state) => state.rootReducer.sidebarReducer.filterSidebarActive
  );
  return (
    <div className="listing-page page">
      <CustomHelmet
        title={t("listingPage.breadcrumbTitle")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader listsActive={"listsActive"} />
      {/* <BreadcrumbSection title={t("listingPage.breadcrumbTitle")} /> */}
      <section>
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3 d-none d-xl-block">
              <ListingSidebar installment={false} />
            </div> */}
            <div className="col-xl-12">
              <ListContent />
            </div>
          </div>
          {/* <div
            className={`responsive-sidebar d-xl-none ${
              sidebarActive ? "active" : ""
            }`}
          >
            <FilterGroup installment={false} />
          </div> */}
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default ListingPage;

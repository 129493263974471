import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllSimilarUnits } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";

const GetSimilarUnitsHook = (id, price) => {
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [unitsCount, setUnitsCount] = useState(1);

  const dispatch = useDispatch();
  const res = useSelector(
    (state) => state.rootReducer.unitsReducer.similarUnits
  );

  const getData = async (queryString) => {
    setLoading(true);
    await dispatch(GetAllSimilarUnits(queryString));
    setLoading(false);
  };
  useEffect(() => {
    if (price) {
      // const minPrice = price;
      const minPrice = Number(price.replace(/,/g, "")) - 500000;
      const maxPrice = Number(price.replace(/,/g, "")) + 500000;
      getData(
        `category_id${id}&price_min=${minPrice.toString()}&price_max=${maxPrice.toString()}`
      );
    }
  }, [id, price]);

  useEffect(() => {
    try {
      if (loading === false) {
        if (res) {
          setUnits(res?.data?.data);
          setUnitsCount(res?.data?.data?.length || 0);
        }
      }
    } catch (e) {}
  }, [loading, res]);

  return [loading, units, unitsCount];
};

export default GetSimilarUnitsHook;

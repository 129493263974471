import React, { useEffect, useState } from "react";
import ListingSidebarHook from "../../../../CustomHooks/Listing/ListingSidebarHook/ListingSidebarHook";
import BtnComponent from "../../../Utility/BtnComponent/BtnComponent";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddSearchParamsHook from "../../../../CustomHooks/helpers/add_search_params_hook";

const FilterItems = ({ unitsType }) => {
  const [minPriceActive, setMinPriceActive] = useState(false);
  const [maxPriceActive, setMaxPriceActive] = useState(false);
  const [priceList, setPriceList] = useState();
  // spaces states
  const [minSpaceActive, setMinSpaceActive] = useState(false);
  const [maxSpaceActive, setMaxSpaceActive] = useState(false);
  const [spaceList, setSpaceList] = useState();
  // installment states
  const [minInstallmentActive, setMinInstallmentActive] = useState(false);
  const [maxInstallmentActive, setMaxInstallmentActive] = useState(false);
  const [installmentList, setInstallmentList] = useState();
  // deposit
  const [minDepositActive, setMinDepositActive] = useState(false);
  const [maxDepositActive, setMaxDepositActive] = useState(false);
  const [depositList, setDepositList] = useState();

  const [, searchParams] = AddSearchParamsHook();

  const codeUrl = searchParams?.get("code") || "";
  const mainCategoryUrl = searchParams?.get("main_category") || "";
  const categoryUrl = searchParams?.get("category") || "";
  const finishingValue = searchParams?.get("finishing") || "";
  const finishingUrl = finishingValue.replace(/\+/g, " ");
  const roomsUrl = searchParams?.get("rooms") || "";
  const bathroomsUrl = searchParams?.get("bathrooms") || "";
  const furnishedUrl = searchParams?.get("furnished") || "";
  const minSpaceUrl = searchParams?.get("min_space") || "";
  const maxSpaceUrl = searchParams?.get("max_space") || "";
  const minPriceUrl = searchParams?.get("min_price") || "";
  const maxPriceUrl = searchParams?.get("max_price") || "";
  const typeUrl = searchParams?.get("type") || "";
  const paying_url = searchParams.get("paying") || "";
  const years_url = searchParams.get("years") || "";
  const delivery_date_url = searchParams.get("delivery_date") || "";
  const finance_url = searchParams.get("finance") || "";
  // deposit
  const deposit_min_url = searchParams.get("deposit_min") || "";
  const deposit_max_url = searchParams.get("deposit_max") || "";
  // installment
  const monthly_installment_min_url =
    searchParams.get("monthly_installment_min") || "";
  const monthly_installment_max_url =
    searchParams.get("monthly_installment_max") || "";

  const fawry_url = searchParams.get("fawry") || "";

  const [
    t,
    onSearch,
    Reset,
    searchWord,
    onSearchSidebar,
    onChangeSearchWord,
    code,
    onChangeCode,
    typeList,
    onChangeType,
    handleTypeLang,
    categoryList,
    onChangeCategoryId,
    categoryValueResult,
    options_3,
    onChangeMinSpace,
    onChangeMaxSpace,
    minSpace,
    maxSpace,
    options_5,
    onChangeRooms,
    options_6,
    onChangeBathrooms,
    formatNumber,
    minPrice,
    maxPrice,
    value,
    handleChange,
    open,
    handleCollaps,
    showState,
    properties_loading,
    properties,
    lang,
    onChangePropertyId,
    handleChangeActive,
    active,
    categories_loading,
    categories,
    onChangeGateId,
    onChangeFinishing,
    onChangeFurnished,
    handleChangeFilterSidebarActive,
    onChangeDistrictId,
    onChangePaying,
    paying_list,
    delivery_date,
    on_change_delivery_date,
    delivery_end_date,
    on_change_delivery_end_date,
    delivery_start_date,
    on_change_delivery_start_date,
    fawry,
    on_change_fawry,
    years,
    on_change_years,
    down_payment,
    on_change_down_payment,
    delivery_date_list,
    onChangeMainCategory,
    onChangeMinPrice,
    onChangeMaxPrice,
    on_change_finance,
    onChangeDeposit,
    onChangeMinDeposit,
    onChangeMaxDeposit,
    onChangeInstallment,
    onChangeMinInstallment,
    onChangeMaxInstallment,
  ] = ListingSidebarHook(unitsType);

  const handleMainCategory = (e) => {
    onChangeMainCategory(e);
  };

  const roomsList = [
    {
      id: "1",
      value: "1",
    },
    {
      id: "2",
      value: "2",
    },
    {
      id: "3",
      value: "3",
    },
    {
      id: "4",
      value: "4",
    },
    {
      id: "5",
      value: "5",
    },
    {
      id: "6",
      value: "+5",
    },
  ];
  const bathroomsList = [
    {
      id: "1",
      value: "1",
    },
    {
      id: "2",
      value: "2",
    },
    {
      id: "3",
      value: "3",
    },
    {
      id: "4",
      value: "4",
    },
    {
      id: "5",
      value: "5",
    },
    {
      id: "6",
      value: "+5",
    },
  ];
  const yearsList = [
    {
      id: "1",
      value: "1",
    },
    {
      id: "2",
      value: "2",
    },
    {
      id: "3",
      value: "3",
    },
    {
      id: "4",
      value: "4",
    },
    {
      id: "5",
      value: "5",
    },
    {
      id: "0",
      value: "+5",
    },
  ];
  const finishingList = [
    {
      id: "1",
      value: "red_bricks",
      label: "طوب احمر",
    },
    {
      id: "2",
      value: "finishing_text ",
      label: " نص تشطيب",
    },
    {
      id: "3",
      value: "super_deluxe ",
      label: "تشطيب كامل",
    },
  ];
  const payList = [
    {
      id: "1",
      value: "cash",
      label: "نقدا",
    },
    {
      id: "2",
      value: "installment",
      label: " تقسيط ",
    },
    {
      id: "3",
      value: "cash_and_insatllment",
      label: "نقدا وتقسيط",
    },
  ];
  const deliveryDateList = [
    {
      id: "1",
      value: "2025",
    },
    {
      id: "2",
      value: "2026",
    },
    {
      id: "3",
      value: "2027",
    },
    {
      id: "4",
      value: "2028",
    },
    {
      id: "5",
      value: "+2028",
    },
  ];
  // generate price list
  const generatePriceList = () => {
    const list = [];
    for (let i = 1; i <= 32; i++) {
      const value = (i * 500000).toString();
      list.push({ id: i.toString(), value });
    }
    setPriceList(list);
  };
  // generate space list
  const generateSpaceList = () => {
    const list = [];
    for (let i = 1; i <= 20; i++) {
      const value = (i * 50).toString();
      list.push({ id: i.toString(), value });
    }
    setSpaceList(list);
  };
  // generate deposit list
  const generateDepositList = () => {
    const list = [];
    for (let i = 1; i <= 20; i++) {
      const value = (i * 100000).toString();
      list.push({ id: i.toString(), value });
    }
    setDepositList(list);
  };
  // generate installment list
  const generateInstallmentList = () => {
    const list = [];
    for (let i = 1; i <= 10; i++) {
      const value = (i * 10000).toString();
      list.push({ id: i.toString(), value });
    }
    setInstallmentList(list);
  };

  useEffect(() => {
    generatePriceList();
    generateSpaceList();
    generateDepositList();
    generateInstallmentList();
  }, []);

  const blur = (val) => {
    if (val === "min") {
      setTimeout(() => {
        setMinPriceActive(false);
        setMinSpaceActive(false);
      }, 500);
    } else {
      setTimeout(() => {
        setMaxPriceActive(false);
        setMaxSpaceActive(false);
      }, 500);
    }
  };
  const spaceBlur = (val) => {
    if (val === "min") {
      setTimeout(() => {
        setMinSpaceActive(false);
      }, 500);
    } else {
      setTimeout(() => {
        setMaxSpaceActive(false);
      }, 500);
    }
  };
  const installmentBlur = (val) => {
    if (val === "min") {
      setTimeout(() => {
        setMinInstallmentActive(false);
      }, 500);
    } else {
      setTimeout(() => {
        setMaxInstallmentActive(false);
      }, 500);
    }
  };
  const depositBlur = (val) => {
    if (val === "min") {
      setTimeout(() => {
        setMinDepositActive(false);
      }, 500);
    } else {
      setTimeout(() => {
        setMaxDepositActive(false);
      }, 500);
    }
  };

  return (
    <div className="filter-group">
      <div className="items-wrapper p-4">
        {unitsType !== "finance" && (
          <div className="form-group p-3 border-bottom">
            <label>الكود</label>
            <div className="form-group fit-flex">
              <input
                className="custom-input bg-light-gray"
                value={codeUrl}
                onChange={onChangeCode}
                type="text"
                placeholder="الكود"
              />
            </div>
          </div>
        )}
        <div className="form-group minCategory-filter-items filter-items m-0 p-2 border-bottom">
          <label>الفئه</label>
          <ul className="d-flex align-items-center justify-content-between flex-wrap my-2">
            <li
              className={`filter-item py-2 px-5 border border-1 rounded pointer my-2 ${
                mainCategoryUrl === "residential" ? " bg-pri text-white" : ""
              }`}
              id="residential"
              onClick={(e) => handleMainCategory(e)}
            >
              سكنى
            </li>
            <li
              className={`filter-item py-2 px-5 border border-1 rounded pointer my-2 ${
                mainCategoryUrl === "Administrative" ? " bg-pri text-white" : ""
              }`}
              id="Administrative"
              onClick={(e) => handleMainCategory(e)}
            >
              ادارى
            </li>
            <li
              className={`filter-item py-2 px-5 border border-1 rounded pointer my-2 ${
                mainCategoryUrl === "commercial" ? " bg-pri text-white" : ""
              }`}
              id="commercial"
              onClick={(e) => handleMainCategory(e)}
            >
              تجارى
            </li>
          </ul>
        </div>
        {unitsType !== "installment" && (
          <div className="form-group p-2 border-bottom">
            <label>القسم</label>
            <ul className="d-flex align-items-center my-2">
              <li
                className={`py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2 ${
                  typeUrl === "sale" ? "bg-pri text-white" : ""
                }`}
                id="sale"
                onClick={(e) => onChangeType(e)}
              >
                بيع
              </li>
              <li
                className={`py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2 ${
                  typeUrl === "rent" ? "bg-pri text-white" : ""
                }`}
                id="rent"
                onClick={(e) => onChangeType(e)}
              >
                ايجار
              </li>
            </ul>
          </div>
        )}
        <div className="form-group category-filter-items p-3 border-bottom">
          <label>نوع العقار</label>
          <ul className="d-flex align-items-center justify-content-between flex-wrap my-2">
            {categoryList[0]?.map((item) => {
              return (
                <li
                  key={item?.id}
                  className={`w-100px p-1 h-100px border border-1 rounded pointer fs-18 text-center fit-flex my-1 user-select-none clicked-btn ${
                    categoryUrl === item?.id ? "bg-pri text-white" : ""
                  }`}
                  id={item?.id}
                  onClick={(e) => onChangeCategoryId(e)}
                >
                  {item?.label}
                </li>
              );
            })}
          </ul>
        </div>
        {unitsType !== "installment" && unitsType !== "finance" && (
          <div className="form-group paying-filter-items filter-items m-0 p-3 border-bottom">
            <label>الدفع</label>
            <ul className="d-flex align-items-center flex-wrap justify-content-between my-2">
              {payList.map((item) => {
                return (
                  <li
                    key={item?.id}
                    className={`filter-item py-2 px-5 border border-1 rounded pointer my-2 user-select-none clicked-btn ${
                      paying_url === item?.value ? "bg-pri text-white" : ""
                    }`}
                    onClick={(e) => onChangePaying(e)}
                    value={item?.value}
                    id={item?.value}
                  >
                    {item?.label}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {/* <div className="form-group p-3 border-bottom">
          <label>الحاله</label>
          <ul className="d-flex align-items-center my-2">
            <li
              className="py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2"
              id="residential"
              onClick={(e) => handleMainCategory(e)}
            >
              من المطور
            </li>
            <li
              className="py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2"
              id="Administrative"
              onClick={(e) => handleMainCategory(e)}
            >
              اعادة بيع
            </li>
          </ul>
        </div> */}
        <div className="form-group finishing-filter-items filter-items m-0 p-3 border-bottom">
          <label>نوع التشطيب</label>
          <ul className="d-flex align-items-center justify-content-between flex-wrap my-2">
            {finishingList.map((item) => {
              return (
                <li
                  key={item?.id}
                  className={`filter-item py-2 px-5 border border-1 rounded pointer my-2 user-select-none clicked-btn ${
                    finishingUrl.includes(item?.value)
                      ? "bg-pri text-white"
                      : ""
                  }`}
                  value={item?.value}
                  onClick={() => onChangeFinishing(item?.value)}
                >
                  {item?.label}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="form-group p-3 border-bottom">
          <label> المساحه بالمتر</label>
          <div className="form-group d-flex align-items-start justify-content-between gap-2">
            <div className="w-100">
              <input
                className="custom-input mx-2 bg-light-gray"
                value={minSpaceUrl}
                onChange={onChangeMinSpace}
                type="text"
                placeholder="من"
                onFocus={() => setMinSpaceActive(true)}
                onBlur={() => spaceBlur("min")}
              />
              {minSpaceActive && (
                <ul className="input-options my-2 w-100 rounded border border-1">
                  {spaceList?.map((item) => {
                    return (
                      <li
                        key={item?.id}
                        className={`py-1 px-2 pointer mx-1 my-1 user-select-none ${
                          minSpaceUrl === item?.value ? "bg-pri text-white" : ""
                        }`}
                        onClick={(e) => onChangeMinSpace(e)}
                        value={item?.value}
                      >
                        {formatNumber(item?.value)}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="w-100">
              <input
                className="custom-input mx-2 bg-light-gray"
                value={maxSpaceUrl}
                onChange={onChangeMaxSpace}
                type="text"
                placeholder="الى"
                onFocus={() => setMaxSpaceActive(true)}
                onBlur={() => spaceBlur("max")}
              />
              {maxSpaceActive && (
                <ul className="input-options my-2 w-100 rounded border border-1">
                  {spaceList?.map((item) => {
                    return (
                      <li
                        key={item?.id}
                        className={`py-1 px-2 pointer mx-1 my-1 user-select-none ${
                          maxSpaceUrl === item?.value ? "bg-pri text-white" : ""
                        }`}
                        onClick={(e) => onChangeMaxSpace(e)}
                        value={item?.value}
                      >
                        {formatNumber(item?.value)}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="form-group p-3 border-bottom">
          <label>الغرف</label>
          <ul className="d-flex align-items-center flex-wrap my-2">
            {roomsList?.map((room) => {
              return (
                <li
                  key={room?.id}
                  className={`py-2 px-3 border border-1 rounded pointer mx-1 my-2 user-select-none clicked-btn ${
                    roomsUrl.includes(+room?.id) ? "bg-pri text-white" : ""
                  }`}
                  onClick={(e) => onChangeRooms(e)}
                  value={room?.id}
                >
                  {room?.value}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="form-group p-3 border-bottom">
          <label>الحمامات</label>
          <ul className="d-flex align-items-center flex-wrap my-2">
            {bathroomsList?.map((bath) => {
              return (
                <li
                  key={bath?.id}
                  className={`py-2 px-3 border border-1 rounded pointer mx-1 my-1 user-select-none clicked-btn ${
                    bathroomsUrl.includes(+bath?.id) ? "bg-pri text-white" : ""
                  }`}
                  onClick={(e) => onChangeBathrooms(e)}
                  id={bath?.id}
                  value={bath?.id}
                >
                  {bath?.value}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="form-group p-3 border-bottom">
          <label>السعر (بالجنيه المصرى)</label>
          <div className="form-group d-flex align-items-start justify-content-between gap-2">
            <div className="w-100">
              <input
                className="custom-input bg-light-gray"
                // value={formatNumber(minPriceUrl)}
                value={minPriceUrl}
                onChange={onChangeMinPrice}
                type="text"
                placeholder="من"
                onFocus={() => setMinPriceActive(true)}
                onBlur={() => blur("min")}
              />
              {minPriceActive && (
                <ul className="input-options price-options my-2 w-100 rounded border border-1">
                  {priceList.map((item) => {
                    return (
                      <li
                        key={item?.id}
                        className={`py-1 px-2 pointer mx-1 my-1 user-select-none ${
                          minPriceUrl === item?.value ? "bg-pri text-white" : ""
                        }`}
                        onClick={(e) => onChangeMinPrice(e)}
                        value={item?.value}
                      >
                        {formatNumber(item?.value)}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="w-100">
              <input
                className="custom-input bg-light-gray"
                // value={formatNumber(maxPriceUrl)}
                value={maxPriceUrl}
                onChange={onChangeMaxPrice}
                type="text"
                placeholder="الى"
                onFocus={() => setMaxPriceActive(true)}
                onBlur={() => blur("max")}
              />
              {maxPriceActive && (
                <ul className="input-options price-options my-2  w-100 rounded border border-1">
                  {priceList.map((item) => {
                    return (
                      <li
                        key={item?.id}
                        className={`py-1 px-2 pointer mx-1 my-1 user-select-none ${
                          maxPriceUrl === item?.value ? "bg-pri text-white" : ""
                        }`}
                        onClick={(e) => onChangeMaxPrice(e)}
                        value={item?.value}
                      >
                        {formatNumber(item?.value)}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
        {unitsType !== "finance" && (
          <div className="form-group p-3 border-bottom">
            <label>تمويل عقارى</label>
            <ul className="d-flex align-items-center my-2">
              <li
                className={`py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2 user-select-none clicked-btn ${
                  finance_url === "1" ? "bg-pri text-white" : ""
                }`}
                id="1"
                value="1"
                onClick={(e) => on_change_finance(e)}
              >
                تصلح
              </li>
              <li
                className={`py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2 user-select-none clicked-btn ${
                  finance_url === "0" ? "bg-pri text-white" : ""
                }`}
                id="0"
                value="0"
                onClick={(e) => on_change_finance(e)}
              >
                لا تصلح
              </li>
            </ul>
          </div>
        )}
        {unitsType !== "finance" && (
          <div className="form-group p-3 border-bottom">
            <label>التسليم</label>
            <ul className="d-flex align-items-center flex-wrap my-2">
              <li
                className={`py-2 px-3 border border-1 rounded pointer mx-1 my-1 user-select-none clicked-btn ${
                  fawry_url === "1" ? "bg-pri text-white" : ""
                }`}
                id="residential"
                onClick={() => on_change_fawry("1")}
              >
                فورى
              </li>
              {deliveryDateList.map((item) => {
                return (
                  <li
                    key={item?.id}
                    className={`py-2 px-3 border border-1 rounded pointer mx-1 my-1 user-select-none clicked-btn ${
                      delivery_date_url === item?.value
                        ? "bg-pri text-white"
                        : ""
                    }`}
                    id={item?.value}
                    value={item?.value}
                    onClick={(e) => on_change_delivery_date(e)}
                  >
                    {item?.value}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="form-group filter-2-item m-0 p-3 border-bottom">
          <label>الفرش</label>
          <ul className="d-flex align-items-center flex-wrap justify-content-between my-2">
            <li
              className={`py-2 filter-item px-5 border border-1 rounded pointer my-2  ${
                furnishedUrl === "Furnished" ? "bg-pri text-white" : ""
              }`}
              value="Furnished"
              onClick={() => onChangeFurnished("Furnished")}
            >
              مفروش
            </li>
            <li
              className={`py-2 filter-item px-5 border border-1 rounded pointer  ${
                furnishedUrl === "Unfurnished" ? "bg-pri text-white" : ""
              }`}
              value="Unfurnished"
              onClick={() => onChangeFurnished("Unfurnished")}
            >
              غير مفروش
            </li>
          </ul>
        </div>
        {unitsType !== "finance" && (
          <div className="form-group p-3 border-bottom">
            <label>مقدم سعر الوحده</label>
            <div className="form-group d-flex align-items-start justify-content-between gap-2">
              <div className="w-100">
                <input
                  className="custom-input bg-light-gray"
                  value={formatNumber(deposit_min_url)}
                  onChange={onChangeMinDeposit}
                  type="text"
                  placeholder="من"
                  onFocus={() => setMinDepositActive(true)}
                  onBlur={() => depositBlur("min")}
                />
                {minDepositActive && (
                  <ul className="input-options my-2 w-100 rounded border border-1">
                    {depositList.map((item) => {
                      return (
                        <li
                          key={item?.id}
                          className={`py-1 px-2 pointer mx-1 my-1 user-select-none ${
                            deposit_min_url === item?.value
                              ? "bg-pri text-white"
                              : ""
                          }`}
                          onClick={(e) => onChangeMinDeposit(e)}
                          value={item?.value}
                        >
                          {formatNumber(item?.value)}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div className="w-100">
                <input
                  className="custom-input bg-light-gray"
                  value={formatNumber(deposit_max_url)}
                  onChange={onChangeMaxDeposit}
                  type="text"
                  placeholder="من"
                  onFocus={() => setMaxDepositActive(true)}
                  onBlur={() => depositBlur("max")}
                />
                {maxDepositActive && (
                  <ul className="input-options my-2 w-100 rounded border border-1">
                    {depositList.map((item) => {
                      return (
                        <li
                          key={item?.id}
                          className={`py-1 px-2 pointer mx-1 my-1 user-select-none ${
                            deposit_max_url === item?.value
                              ? "bg-pri text-white"
                              : ""
                          }`}
                          onClick={(e) => onChangeMaxDeposit(e)}
                          value={item?.value}
                        >
                          {formatNumber(item?.value)}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
        {unitsType !== "finance" && (
          <div className="form-group p-3 border-bottom">
            <label>سنوات القسط</label>
            <ul className="d-flex align-items-center flex-wrap my-2">
              {yearsList?.map((year) => {
                return (
                  <li
                    key={year?.id}
                    className={`py-2 px-3 border border-1 rounded pointer mx-1 my-1 user-select-none clicked-btn ${
                      years_url === year?.id ? "bg-pri text-white" : ""
                    }`}
                    onClick={(e) => on_change_years(e)}
                    value={year?.id}
                    id={year?.id}
                  >
                    {year?.value}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {unitsType !== "finance" && (
          <div className="form-group p-3 border-bottom">
            <label>القسط الشهرى</label>
            <div className="form-group d-flex align-items-start justify-content-between gap-2">
              <div className="w-100">
                <input
                  className="custom-input bg-light-gray"
                  value={monthly_installment_min_url}
                  onChange={onChangeMinInstallment}
                  type="text"
                  placeholder="من"
                  onFocus={() => setMinInstallmentActive(true)}
                  onBlur={() => installmentBlur("min")}
                />
                {minInstallmentActive && (
                  <ul className="input-options my-2 w-100 rounded border border-1">
                    {installmentList.map((item) => {
                      return (
                        <li
                          key={item?.id}
                          className={`py-1 px-2 pointer mx-1 my-1 user-select-none ${
                            monthly_installment_min_url === item?.value
                              ? "bg-pri text-white"
                              : ""
                          }`}
                          onClick={(e) => onChangeMinInstallment(e)}
                          value={item?.value}
                        >
                          {formatNumber(item?.value)}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div className="w-100">
                <input
                  className="custom-input bg-light-gray"
                  value={monthly_installment_max_url}
                  onChange={onChangeMaxInstallment}
                  type="text"
                  placeholder="الى"
                  onFocus={() => setMaxInstallmentActive(true)}
                  onBlur={() => installmentBlur("max")}
                />
                {maxInstallmentActive && (
                  <ul className="input-options my-2 w-100 rounded border border-1">
                    {installmentList.map((item) => {
                      return (
                        <li
                          key={item?.id}
                          className={`py-1 px-2 pointer mx-1 my-1 user-select-none ${
                            monthly_installment_max_url === item?.value
                              ? "bg-pri text-white"
                              : ""
                          }`}
                          onClick={(e) => onChangeMaxInstallment(e)}
                          value={item?.value}
                        >
                          {formatNumber(item?.value)}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="form-group p-2 d-flex align-items-center justify-content-evenly">
        <BtnComponent
          onClick={() => onSearchSidebar()}
          value={"عرض النتائح"}
          icon={<FontAwesomeIcon icon={faEye} />}
          st={"wd-fit"}
        />
        <BtnComponent
          onClick={() => Reset()}
          value={"اعادة ضبط"}
          // icon={<FontAwesomeIcon icon={faTrash} />}
          st={"wd-fit"}
        />
      </div>
    </div>
  );
};

export default FilterItems;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import person from "../../../images/anonymous.png";
import { Link } from "react-router-dom";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
const CompoundCard = ({ item }) => {
  const [active, setActive] = useState(false);
  const handleChangeActive = () => {
    setActive(!active);
  };
  const [t] = useTranslation();
  const lang = Cookies.get("i18next");
  return (
    <div className="new-card  compound-card border border-1 radius-10 p-2 position-relative">
      <div className="card-head h-250px pointer radius-10 overflow-hidden position-relative w-100 flex-shrink-0">
        <Link to={`/compound-details/:${item?.id}`}>
          <img
            style={{ height: "188px" }}
            src={item?.cover_image}
            className="h-100 w-100 position-relative"
            alt="compound-img"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="card-body bg-white h-150px w-100 px-2 py-2 txt-start overflow-hidden flex-shrink-1">
        <div className="info-wrapper">
          <div className="mb-3">
            <h5 className="text-capitalize fw-bold fs-18 unit-title m-0 ">
              {lang === "en"
                ? item?.data_trans?.[1]?.title_en ||
                  item?.data_trans?.[0]?.title_en
                : item?.data_trans?.[0]?.title_ar ||
                  item?.data_trans?.[1]?.title_ar}
            </h5>
            <p className="location text-capitalize my-1 fs-14 fw-medium text-secondary ellipses">
              <span>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </span>
              <span className="mx-2">{item?.address}</span>
            </p>
          </div>
          <div className="developer-content d-flex  flex-nowrap">
            <img
              src={item?.Developer_name?.company_name || person}
              className="w-35px h-35px radius-50 flex-shrink-0"
              loading="lazy"
              alt="dev-img"
            />
            <div className="dev-info mx-2">
              <h6 className="m-0 fs-14 fw-bold ellipses">
                {item?.Developer_name?.name || null}
              </h6>
              <p className="m-0 fs-14">
                {item?.Developer_name?.projects_count || null}{" "}
                {t("compoundPage.project")}
              </p>
            </div>
          </div>
        </div>
        <ul className="list-row my-1 align-items-center contacts position-absolute bottom-0">
          <li className="list-row-item my-1">
            <a
              href={`https://wa.me/+20${item?.user?.phone}`}
              target="_blank"
              rel="noreferrer"
              className="btn-social whatsapp"
            >
              <i className="fa fa-whatsapp"></i>
            </a>
          </li>
          <li className="list-row-item my-1">
            <a
              href={`tel:+20${item?.user?.phone}`}
              target="_blank"
              rel="noreferrer"
              className="btn-social twitter"
            >
              <i className="fa fa-phone"></i>
            </a>
          </li>
          <li className="list-row-item my-1">
            <button
              className="btn-social share"
              onClick={() => handleChangeActive()}
            >
              <i className="fa fa-share"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CompoundCard;

import React from "react";
import Select from "react-select";

const SelectComponent = ({ options, placeholder, value, onChange }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#F5F5F5",
      borderColor: "#F5F5F5",
      borderRadius: "10px",
      border: "0",
      minHeight: "50px",
      height: "50px",
      margin: "0 5px",
      zIndex: 1,
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (provided, state) => ({
      ...provided,
      // width: state.selectProps.width,
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      padding: 8,
      zIndex: 10,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "100%",
      padding: "0 6px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      height: "50px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "100%",
    }),
  };
  return (
    <Select
      placeholder={placeholder}
      defaultValue={value}
      value={value}
      styles={customStyles}
      options={options}
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: "#f3b01c",
        },
      })}
    />
  );
};

export default SelectComponent;

import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import MallUnits from "./MallUnits/MallUnits";
import GetMallDetailsHook from "../../../CustomHooks/Malls/GetMallDetailsHook";
import { useParams } from "react-router-dom";
import HomeSectionSkeleton from "../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import Cookies from "js-cookie";
import SingleDetailsSidebar from "../../../components/SingleDetails/SingleDetailsSidebar/SingleDetailsSidebar";
import SingleDetailDesc from "../../../components/SingleDetails/Features/SingleDetailDesc/SingleDetailDesc";
import SingleDetailAmenities from "../../../components/SingleDetails/Features/SingleDetailAmenities/SingleDetailAmenities";
import ImagesSlider from "../../../components/SingleDetails/ImagesSlider/ImagesSlider";
import DetailsTable from "../../../components/Utility/DetailsTable/DetailsTable";
const MallDetailsPage = () => {
  const { id } = useParams();
  const [t] = useTranslation();
  const [loading, mallDetails] = GetMallDetailsHook(id?.slice(1, id.length));
  const lang = Cookies.get("i18next");
  // console.log(mallDetails);
  return (
    <div className="malls-page single-details-page ">
      <CustomHelmet
        title={t("mallsDetailsPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader mallsActive={"mallsActive"} />
      {/* <BreadcrumbSection title={t("mallsDetailsPage.title")} /> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {loading === false ? (
                <>
                  <div className="details-head">
                    {mallDetails?.images?.length > 0 ? (
                      <ImagesSlider items={mallDetails.images} />
                    ) : (
                      <img
                        src={mallDetails?.cover_image}
                        alt="offer-img"
                        loading="lazy"
                        className="w-100 radius-10 h-500px"
                      />
                    )}
                    <div className="p-2 radius-10 border border-2 txt-start my-3">
                      <h5 className="fw-bold m-0">
                        {lang === "en"
                          ? mallDetails?.data_trans?.[0]?.title_en
                          : mallDetails?.data_trans?.[1].title_ar}
                      </h5>
                      <p className="location text-capitalize my-2 text-secondary fs-16 fw-medium">
                        <span className=" text-secondary">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        <span className="mx-2">{mallDetails?.address}</span>
                      </p>
                    </div>
                  </div>
                  <DetailsTable data={mallDetails} />
                  <SingleDetailDesc unitDetails={mallDetails} />
                  {mallDetails?.property?.length > 0 && (
                    <SingleDetailAmenities unitDetails={mallDetails} />
                  )}
                  <MallUnits units={mallDetails?.units} />
                </>
              ) : (
                <>
                  <HomeSectionSkeleton />
                  <HomeSectionSkeleton />
                  <HomeSectionSkeleton />
                </>
              )}
            </div>
            <div className="col-lg-4 mb-5">
              <SingleDetailsSidebar item={mallDetails} />
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default MallDetailsPage;

import React from "react";
import "./Cta.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
const Cta = () => {
  const [t] = useTranslation();
  return (
    <section className="cta mx-auto p-4 position-relative overflow-hidden ">
      <div className="cta-overlay"></div>
      <div className="container align-items-center position-relative">
        <div className="row">
          <div className="col-12 txt-start">
            <h2 className="text-uppercase fw-bold color-primary">
              {" "}
              {t("homePage.cta.offers")} {t("homePage.cta.title")}{" "}
            </h2>
          </div>
          <div className="col-md-10">
            <p className="col-md-10 text-capitalize text-white txt-start">
              {t("homePage.cta.des")}
            </p>
          </div>
          <div className="btn-box col-md-2">
            <Link
              to="/offers"
              className="request-btn my-2 text-uppercase d-block h-100"
            >
              <BtnComponent value={t("homePage.cta.more")} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;

import React from "react";
import { useTranslation } from "react-i18next";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import JobsListContent from "../../components/Jobs/JobsListContent";

const JobsPage = () => {
  const { t } = useTranslation();
  return (
    <div className="listing-page page">
      <CustomHelmet
        title={t("jobs_page.breadcrumbTitle")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader />
      {/* <BreadcrumbSection title={t("jobs_page.breadcrumbTitle")} /> */}
      <section>
        <div className="container">
          <JobsListContent />
        </div>
      </section>
    </div>
  );
};

export default JobsPage;

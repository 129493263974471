import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active: false,
  filterSidebarActive: false,
};
const sidebarSlice = createSlice({
  // name of this slice
  name: "sidebarSlice",
  // the initial state
  initialState: initialState,
  // create the reducers
  reducers: {
    changeActive(state) {
      state.active = !state.active;
    },
    changeFilterSidebarActive(state) {
      state.filterSidebarActive = !state.filterSidebarActive;
    },
  },
});
// export the reducer of this slice
export const sidebarReducer = sidebarSlice.reducer;
// export the actions of this slice
export const { changeActive, changeFilterSidebarActive } = sidebarSlice.actions;

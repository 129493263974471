import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllDevelopers } from "../../Redux/Redux-Toolkit/Slices/Developers/DevelopersSlice";

const GetAllDevelopersHook = () => {
  const [loading, setLoading] = useState(false);
  const [developers, setDevelopers] = useState([]);
  const [developersCount, setDevelopersCount] = useState(1);
  const [meta, setMeta] = useState();
  const [error, setError] = useState(null); // Add state to store error
  const res = useSelector(
    (state) => state.rootReducer.DevelopersReducer.allDevelopers
  );
  const dispatch = useDispatch();

  const getData = async (page) => {
    setLoading(true);
    setError(null); // Clear any existing error
    try {
      await dispatch(GetAllDevelopers(page));
    } catch (error) {
      setError(error); // Store error in state
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onPressPaginate = (page) => {
    getData(`page=${page}`);
  };

  useEffect(() => {
    if (loading === false && res?.data?.data) {
      setDevelopers(res.data.data);
      setDevelopersCount(res?.data?.data?.length || 0);
      setMeta(res?.data?.meta);
    }
  }, [loading, res]);

  return [loading, developers, developersCount, error, onPressPaginate, meta];
};

export default GetAllDevelopersHook;

import { useState } from "react";
import BtnComponent from "../Utility/BtnComponent/BtnComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
} from "@fortawesome/free-solid-svg-icons";

export const ReadMore = ({ id, content, amountOfWords = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Create a temporary element to parse the HTML and extract text content
  const parseContent = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText?.split(" ");
  };

  const splittedText = parseContent(content);
  const itCanOverflow = splittedText.length > amountOfWords;

  // Slice the text for beginning and ending
  const beginText = itCanOverflow
    ? splittedText?.slice(0, amountOfWords)?.join(" ")
    : content; // Return full content if no overflow

  const endText = itCanOverflow
    ? splittedText?.slice(amountOfWords)?.join(" ")
    : "";

  const handleKeyboard = (e) => {
    if (e.code === "Space" || e.code === "Enter") {
      e.preventDefault(); // Prevent default action (scrolling)
      setIsExpanded((prev) => !prev);
    }
  };

  return (
    <div id={id} className="details-table">
      <span
        dangerouslySetInnerHTML={{ __html: isExpanded ? content : beginText }}
      />
      {itCanOverflow && (
        <>
          {!isExpanded && <span>... </span>}
          {isExpanded && <span dangerouslySetInnerHTML={{ __html: endText }} />}
          <div
            className="text-violet-400 ml-2"
            role="button"
            tabIndex={0}
            aria-expanded={isExpanded}
            aria-controls={id}
            onKeyDown={handleKeyboard}
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <BtnComponent
              st={"wd-fit my-2"}
              value={isExpanded ? "show less" : "show more"}
              icon={
                isExpanded ? (
                  <FontAwesomeIcon icon={faArrowAltCircleUp} />
                ) : (
                  <FontAwesomeIcon icon={faArrowAltCircleDown} />
                )
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

import React from "react";
import DevelopersCarousel from "./DevelopersCarousel.js/DevelopersCarousel";
import GetAllDevelopersHook from "../../../CustomHooks/Developers/GetAllDevelopersHook";
import NotFound from "../../Utility/NotFound/NotFound";
import SectionTitle from "../../Utility/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";

const Developers = () => {
  const [loading, developers, developersCount, ,] = GetAllDevelopersHook();
  const [t] = useTranslation();
  return (
    <section className="developers py-5 px-4">
      <div className="container">
        <SectionTitle
          title={t("developers.developers")}
          des={t("developers.find")}
        />
        <div className="position-relative d-flex flex-wrap flex-sm-nowrap justify-content-around align-content-center">
          {developersCount > 0 ? (
            <DevelopersCarousel items={developers} loading={loading} />
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </section>
  );
};

export default Developers;

import React from "react";
import GetAllCategoriesHook from "../../../CustomHooks/Categories/GetAllCategoriesHook";
import Cookies from "js-cookie";
import CountUp from "../../Utility/CountUp/CountUp";

const CategoriesAnalysis = () => {
  const [loading, categories] = GetAllCategoriesHook();
  const lang = Cookies.get("i18next");

  return (
    <section className="categories-analysis bg-sec-2 py-5 px-4">
      <div className="cta-overlay"></div>
      <div className="analysis-wrapper position-relative d-flex flex-wrap flex-sm-nowrap justify-content-around align-content-center">
        {loading === false
          ? categories?.map((item) => {
              return item?.count_unit !== 0 ? (
                <CountUp
                  key={item?.id}
                  title={
                    lang === "en"
                      ? item?.data_trans
                        ? item?.data_trans[1]?.title_en
                        : null
                      : item?.data_trans
                      ? item?.data_trans[0]?.title_ar
                      : null
                  }
                  countUnit={"+" + item?.count_unit}
                />
              ) : null;
            })
          : null}
      </div>
    </section>
  );
};

export default CategoriesAnalysis;

import React from "react";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import CustomPagination from "../../../components/Utility/CustomPagination/CustomPagination";
// import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection"
import ServiceCard from "../../../components/Services/ServiceCard/ServiceCard";
import ServiceCardSkeleton from "../../../components/Services/ServiceCardSkeleton/ServiceCardSkeleton";
import GetAllServiceListHook from "../../../CustomHooks/Services/GetAllServiceListHook";
import { useParams } from "react-router-dom";
import NotFound from "../../../components/Utility/NotFound/NotFound";
import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const ServiceListPage = () => {
  const { id } = useParams();
  const [loading, serviceList, onPressPaginate] = GetAllServiceListHook(
    id?.slice(1, id.length)
  );
  const [t] = useTranslation();
  return (
    <div className="service-list-page agency-page page">
      <CustomHelmet
        title={t("servicePage.serviceList")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader servicesActive={"servicesActive"} />
      {/* <BreadcrumbSection title={t("servicePage.serviceList")} /> */}
      <section>
        <div className="profile__agency">
          <div className="container">
            <div className="row projects-wrapper no-gutters min-h-100">
              {loading === false ? (
                serviceList?.Services?.length > 0 ? (
                  serviceList?.Services?.map((item) => {
                    return (
                      <div
                        className="col-sm-6 col-md-4 col-lg-3 mt-0 my-3"
                        key={item?.id}
                      >
                        <ServiceCard item={item} />
                      </div>
                    );
                  })
                ) : (
                  <NotFound />
                )
              ) : (
                <>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <ServiceCardSkeleton />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <ServiceCardSkeleton />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <ServiceCardSkeleton />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <ServiceCardSkeleton />
                  </div>
                </>
              )}
            </div>
            {serviceList?.Services?.length > 0 && (
              <div className="pagination-content d-flex align-items-center justify-content-center">
                <CustomPagination
                  totalPages={serviceList?.meta?.last_page}
                  currentPage={serviceList?.meta?.current_page}
                  onChange={onPressPaginate}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceListPage;

import React from "react";
import "./DetailsTable.css";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const DetailsTable = ({ data }) => {
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();
  return (
    <div className="details-table single__detail-features single-detail-info txt-start">
      <h5 className="text-capitalize detail-heading fw-bold fs-22 color-primary">
        {t("singleDetailsPage.singleDetailsInfo.title")}
      </h5>
      <table>
        <tr>
          <td>اسم المشروع</td>
          <td>
            {lang === "en"
              ? data?.data_trans?.[1]?.title_en ||
                data?.data_trans?.[0]?.title_en
              : data?.data_trans?.[0]?.title_ar ||
                data?.data_trans?.[1]?.title_ar}
          </td>
        </tr>
        <tr>
          <td>المطور</td>
          <td>{data?.Developer_name?.name || data?.Developer_name}</td>
        </tr>
        <tr>
          <td>المنطقة</td>
          <td>{data?.address}</td>
        </tr>
        <tr>
          <td>انواع الوحدات</td>
          <td>
            {data?.type_units?.map((e) => {
              return lang === "en"
                ? e?.data_trans?.[0]?.title_en + " , " ||
                    e?.data_trans?.[1]?.title_en + " , "
                : e?.data_trans?.[1]?.title_ar + " , " ||
                    e?.data_trans?.[0]?.title_ar + " , ";
            })}
          </td>
        </tr>
        <tr>
          <td>نوع التشطيب</td>
          <td>{data?.finish_type}</td>
        </tr>
        <tr>
          <td>المساحات تبدأ من</td>
          <td>{data?.spaces}</td>
        </tr>
        <tr>
          <td>الاسعار تبدا من</td>
          <td>
            {data?.price}
            <span className="color-primary mx-1 fw-bold">EG</span>
          </td>
        </tr>
        <tr>
          <td>نظام السداد</td>
          <td>{data?.method_payment}</td>
        </tr>
        <tr>
          <td>التسليم</td>
          <td>{data?.delivery_date}</td>
        </tr>
      </table>
    </div>
  );
};

export default DetailsTable;

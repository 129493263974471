import React from "react";
import DeveloperCard from "../../../../components/Developers/DeveloperCard/DeveloperCard";
import NotFound from "../../../../components/Utility/NotFound/NotFound";
import { useNavigate } from "react-router-dom";
import GetAllDevelopersHook from "../../../../CustomHooks/Developers/GetAllDevelopersHook";
import CustomPagination from "../../../../components/Utility/CustomPagination/CustomPagination";
import HomeSectionSkeleton from "../../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";

const DevelopersListContent = () => {
  const Navigate = useNavigate();
  const [loading, developers, developersCount, , onPressPaginate, meta] =
    GetAllDevelopersHook();
  return (
    <div className="compounds-list min-h-100">
      <div className="row units-wrapper">
        {loading === false ? (
          developersCount > 0 ? (
            developers?.map((item) => (
              <div
                className="col-sm-4 col-lg-3"
                key={item?.id}
                onClick={() => Navigate(`/developer-details/:${item?.id}`)}
              >
                <DeveloperCard item={item} />
              </div>
            ))
          ) : (
            <NotFound />
          )
        ) : (
          <>
            <HomeSectionSkeleton />
            <HomeSectionSkeleton />
          </>
        )}
      </div>
      <div className="row my-5">
        {developersCount > 1 && (
          <CustomPagination
            totalPages={meta?.last_page}
            currentPage={meta?.current_page}
            onChange={onPressPaginate}
          />
        )}
      </div>
    </div>
  );
};

export default DevelopersListContent;

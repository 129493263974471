import React from "react";
import { useTranslation } from "react-i18next";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowPointer,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import DisplayWordpadContent from "../../components/Utility/DisplayWordpadContent/DisplayWordpadContent";
import { Link, useParams } from "react-router-dom";
import HomeSectionSkeleton from "../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import BtnComponent from "../../components/Utility/BtnComponent/BtnComponent";
import GetJobDetailsHook from "../../CustomHooks/Jobs/GetJobDetailsHook";

const JobDetailsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, jobDetails] = GetJobDetailsHook(id);
  return (
    <div className="listing-page page">
      <CustomHelmet
        title={t("job_details.breadcrumbTitle")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader offersActive={"offersActive"} />
      {/* <BreadcrumbSection title={t("job_details.breadcrumbTitle")} /> */}
      <section>
        <div className="container min-h-100">
          <div className="row">
            <div className="col-lg-12">
              {loading === false ? (
                <>
                  <div className="details-head">
                    <div className="p-2 radius-10 border border-2 txt-start my-3 d-flex align-items-center justify-content-between">
                      <div>
                        <h3 className="fw-bold color-sec">
                          {jobDetails?.title}
                        </h3>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="location text-capitalize text-secondary fs-14 fw-medium ellipses">
                            <span className="color-primary">
                              <FontAwesomeIcon icon={faLocationDot} />
                            </span>
                            <span className="mx-2">{jobDetails?.address}</span>
                          </p>
                        </div>
                      </div>
                      <Link to={`/apply-job/${jobDetails?.id}`}>
                        <BtnComponent
                          st={"wd-fit my-3 fw-normal"}
                          value={"التقديم على الوظيفه"}
                          icon={<FontAwesomeIcon icon={faArrowPointer} />}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="details-body txt-start p-4">
                    <h5 className="fw-bold my-3 text-capitalize color-sec">
                      {t("job_details.breadcrumbTitle")}{" "}
                    </h5>
                    <DisplayWordpadContent content={jobDetails?.description} />
                    <Link to={`/apply-job/${jobDetails?.id}`}>
                      <BtnComponent
                        st={"wd-fit my-3 fw-normal"}
                        value={"التقديم على الوظيفه"}
                        icon={<FontAwesomeIcon icon={faArrowPointer} />}
                      />
                    </Link>
                  </div>
                </>
              ) : (
                <HomeSectionSkeleton />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JobDetailsPage;

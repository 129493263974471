import React, { useState } from "react";
import "../authPages.css";
import { Link } from "react-router-dom";
import LoginHook from "../../../CustomHooks/Auth/LoginHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import BtnComponent from "../../../components/Utility/BtnComponent/BtnComponent";
import AuthTitle from "../../../components/Auth/AuthTitle/AuthTitle";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import img from "../../../images/شاهد عقار حدائق اكتوبر-02.png";

const LoginPage = () => {
  const [showPassWordIcon, setShowPasswordIcon] = useState(faEyeSlash);
  const [passwordState, setPasswordState] = useState("password");
  const [
    loginValue,
    password,
    onChangeLoginValue,
    onChangePassword,
    onSubmit,
    loading,
    isPress,
  ] = LoginHook();

  const handlePasswordState = () => {
    passwordState === "password"
      ? setPasswordState("text")
      : setPasswordState("password");
    showPassWordIcon === faEyeSlash
      ? setShowPasswordIcon(faEye)
      : setShowPasswordIcon(faEyeSlash);
  };
  const lang = Cookies.get("i18next");
  const handleShowPassword = () => {
    return lang === "en" ? "show-password" : "show-password-ar";
  };
  const [t] = useTranslation();
  return (
    <div className="login-page auth-page page">
      <CustomHelmet
        title={t("authPages.loginPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <section className="section p-0 m-0">
        <div className="row w-100 h-100">
          <div className="auth-content col-12 col-lg-5 d-flex align-items-center justify-content-center">
            <div>
              <div className="auth-page-title">
                <AuthTitle title={t("authPages.loginPage.title")} />
              </div>
              <div className="login-card auth-card ">
                <div className="card-body">
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label className="my-2">
                        {t("authPages.loginPage.emailOrPhone")}
                      </label>
                      <input
                        value={loginValue}
                        onChange={onChangeLoginValue}
                        className="custom-input"
                        placeholder={t("authPages.loginPage.emailOrPhone")}
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group form-password">
                      <label className="my-2">
                        {t("authPages.loginPage.password")}
                      </label>
                      <span
                        className={handleShowPassword()}
                        onClick={handlePasswordState}
                      >
                        <FontAwesomeIcon icon={showPassWordIcon} />
                      </span>
                      <input
                        value={password}
                        onChange={onChangePassword}
                        className="custom-input"
                        placeholder={t("authPages.loginPage.password")}
                        type={passwordState}
                        required
                      />
                    </div>
                    <div className="form-group">
                      {/* <Link to="/forget-pass-page" className="float-right">
                    Forgot password?
                  </Link> */}
                    </div>
                    <div className="form-group">
                      <BtnComponent
                        value={t("authPages.loginPage.title")}
                        type={"submit"}
                        loading={
                          loading === true && isPress === true ? true : false
                        }
                      />
                    </div>
                  </form>
                </div>
                <div className="sign-up-info p-4">
                  <p className="text-center">
                    {t("authPages.loginPage.dontHaveAccount")}{" "}
                    <Link to="/sign-up">{t("authPages.loginPage.signUp")}</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mask-auth col-7 d-none d-lg-block">
            <div className="fit-flex w-100 h-100">
              <img src={img} alt="logo" loading="lazy" className="w-50" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPage;

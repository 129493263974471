import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import CompoundsListContent from "../../../components/Compounds/CompoundsListContent/CompoundsListContent";
const CompoundsPage = () => {
  const { t } = useTranslation();
  return (
    <div className="compounds-page page">
      <CustomHelmet
        title={t("compoundPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader compoundActive={"compoundActive"} />
      {/* <BreadcrumbSection title={t("compoundPage.title")} /> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <CompoundsListContent />
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default CompoundsPage;

import React from "react";
import { Link } from "react-router-dom";
const DeveloperCard = ({ item }) => {
  return (
    <div className="developer-card text-black text-capitalize d-flex flex-column align-items-center">
      <Link
        to={`/developer-details/:${item?.id}`}
        className="d-block p-2 rounded-circle w-150px h-150px border-1-primary"
      >
        <img
          className="w-100 h-100 rounded-circle overflow-hidden"
          src={item?.cover}
          alt="dev-img"
          loading="lazy"
        />
      </Link>
      <p className="m-0 fw-bold fs-18">{item?.name}</p>
      <p className="m-0 color-primary">{item?.project?.length || 0}</p>
    </div>
  );
};

export default DeveloperCard;

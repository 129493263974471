import React, { useState } from "react";
import "./MallCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const MallCard = ({ item }) => {
  const [active, setActive] = useState(false);
  const handleChangeActive = () => {
    setActive(!active);
  };
  return (
    <div className="mall-card w-200px h-250px">
      <div className="card-head pointer radius-10 overflow-hidden position-relative">
        <Link
          to={`/mall-details/:${item?.id}`}
          className="d-block w-200px h-200px"
        >
          <img
            src={item?.cover_image}
            className="h-100 w-100 position-relative"
            alt="mall-img"
            loading="lazy"
          />
        </Link>
        <ul className="list-row my-1 w-100 align-items-center justify-content-center contacts position-absolute bottom-0">
          <li className="list-row-item my-1">
            <a
              href={`https://wa.me/+20${item?.user?.phone}`}
              target="_blank"
              rel="noreferrer"
              className="btn-social whatsapp"
            >
              <i className="fa fa-whatsapp"></i>
            </a>
          </li>
          <li className="list-row-item my-1">
            <a
              href={`tel:+20${item?.user?.phone}`}
              target="_blank"
              rel="noreferrer"
              className="btn-social twitter"
            >
              <i className="fa fa-phone"></i>
            </a>
          </li>
          <li className="list-row-item my-1">
            <button
              className="btn-social share"
              onClick={() => handleChangeActive()}
            >
              <i className="fa fa-share"></i>
            </button>
          </li>
        </ul>
      </div>
      <div className="card-body bg-white px-2 py-2">
        <div className="unit-info my-1 d-flex  justify-content-between flex-nowrap">
          <div className="info-wrapper text-center w-100">
            <h5 className="text-capitalize fw-bold unit-title m-0 fs-18">
              {item?.data_trans ? item?.data_trans[0].title_en : "..."}
            </h5>
            <p className="location ellipses text-capitalize my-2 fs-14 text-secondary">
              <span>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </span>
              <span className="mx-2">{item?.address || "...."}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MallCard;

import React from "react";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
// import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import GetServiceDetailsHook from "../../../CustomHooks/Services/GetServiceDetailsHook";
import HomeSectionSkeleton from "../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import DisplayWordpadContent from "../../../components/Utility/DisplayWordpadContent/DisplayWordpadContent";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ContactButtons from "../../../components/UI/contact-btns/ContactButtons";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import ImagesSlider from "../../../components/SingleDetails/ImagesSlider/ImagesSlider";
const ServiceDetailsPage = () => {
  const { id } = useParams();
  const [loading, serviceDetails] = GetServiceDetailsHook(
    id?.slice(1, id.length)
  );
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();
  return (
    <div className="service-details-page agency-details-page page">
      <CustomHelmet
        title={t("servicePage.serviceDetails")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader servicesActive={"servicesActive"} />
      {/* <BreadcrumbSection title={t("servicePage.serviceDetails")} /> */}

      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="p-2 radius-10 border border-2 txt-start my-3">
                <h6 className="fw-bold m-0">
                  {lang === "en"
                    ? serviceDetails?.data_trans
                      ? serviceDetails?.data_trans[1]?.title_en
                      : null
                    : serviceDetails?.data_trans
                    ? serviceDetails?.data_trans[0]?.title_ar
                    : null}
                </h6>
                <p className="location text-capitalize my-2 text-secondary fs-14 fw-medium">
                  <span className=" text-secondary">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </span>
                  <span className="mx-2">{serviceDetails?.address}</span>
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              {loading === false ? (
                <>
                  <div className="details-head">
                    {serviceDetails?.images?.length > 1 ? (
                      <ImagesSlider items={serviceDetails?.images} />
                    ) : (
                      <img
                        src={serviceDetails?.images?.[0].image}
                        alt="offer-img"
                        loading="lazy"
                        className="w-100 radius-10 h-500px"
                      />
                    )}
                  </div>
                  <div className="details-body txt-start p-4">
                    <h5 className="fw-bold my-3 text-capitalize">
                      {t("servicePage.serviceDetails")}{" "}
                    </h5>
                    {serviceDetails?.data_trans ? (
                      lang === "en" ? (
                        <DisplayWordpadContent
                          content={
                            serviceDetails?.data_trans[1]?.description_en
                          }
                        />
                      ) : (
                        <DisplayWordpadContent
                          content={
                            serviceDetails?.data_trans[0]?.description_ar
                          }
                        />
                      )
                    ) : null}
                  </div>
                </>
              ) : (
                <HomeSectionSkeleton />
              )}
            </div>
            <div className="col-lg-4">
              <ContactButtons
                whatsapp={serviceDetails?.phone}
                phone={serviceDetails?.phone}
                facebook={serviceDetails?.facebook}
                instagram={serviceDetails?.instagram}
              />
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default ServiceDetailsPage;

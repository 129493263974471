import React, { useState } from "react";
import "./Card.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Navigation } from "swiper/modules";
import blackHeart from "../../../../images/heart.png";
import redHeart from "../../../../images/like.png";
import CardHook from "../../../../CustomHooks/Card/CardHook";
import FormatNumberHook from "../../../../CustomHooks/FormatNumber/FormatNumberHook";
import ShareCard from "../../Share/ShareCard/ShareCard";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import img from "../../../../images/anonymous-image.png";
const Card = ({ item, favItem }) => {
  const Navigate = useNavigate();
  const [handleClickWishlist, isFav, wishlistCount] = CardHook(item, favItem);
  const [formatNumber] = FormatNumberHook();
  const [active, setActive] = useState(false);
  const handleChangeActive = () => {
    setActive(!active);
  };
  const { t, i18n } = useTranslation();
  const langDirection = i18n.dir();
  const lang = Cookies.get("i18next");
  return (
    <motion.div
      className="card-item item position-relative"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      layout
      id={item?.id}
    >
      <div className="card__image card__box">
        <div className="card__image-header h-250">
          {item?.for_sale === 1 && (
            <div className="ribbon text-capitalize">
              {t("utility.cards.card.soldOut")}
            </div>
          )}
          <Swiper
            style={{
              "--swiper-navigation-color": "white",
              "--swiper-navigation-size": "25px",
            }}
            navigation={true}
            modules={[Navigation]}
            dir={!langDirection ? "rtl" : "ltr"}
            key={langDirection}
            className="my-card-swiper"
          >
            <SwiperSlide
              onClick={() => Navigate(`/single-details/:${item?.id}`)}
            >
              <div className="img-wrapper w-100 h-100 position-relative">
                <div className="overlay_2"></div>
                <img
                  loading="lazy"
                  src={item?.cover_image || img}
                  alt="unit-img"
                  className="img-fluid w100 img-transition"
                />
              </div>
            </SwiperSlide>
            {item?.images?.map((img, i) => {
              return (
                <SwiperSlide
                  key={i}
                  onClick={() => Navigate(`/single-details/:${item?.id}`)}
                >
                  <div className="img-wrapper w-100 h-100 position-relative">
                    <div className="overlay_2"></div>
                    <img
                      loading="lazy"
                      src={img?.image}
                      alt="unit-img"
                      className="img-fluid w100 img-transition"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="info">
            {item?.type === "sale"
              ? t("utility.cards.card.forSale")
              : t("utility.cards.card.forRent")}
          </div>
        </div>
        <div className="card__image-body">
          <div
            className="body-content"
            onClick={() => Navigate(`/single-details/:${item?.id}`)}
          >
            <div className="badges d-flex align-items-center justify-content-between">
              <span className="unit-category badge badge-primary text-capitalize">
                {lang === "en"
                  ? item?.category?.data_trans?.[1]?.title_en ||
                    item?.category?.data_trans?.[0]?.title_en
                  : item?.category?.data_trans?.[0]?.title_ar ||
                    item?.category?.data_trans?.[1]?.title_ar}
              </span>
              <div className="right-side-badges">
                <div className="unit-code p-1 radius-10 bg-light-gray">
                  <span className="mx-1 fw-bold">
                    {t("utility.cards.card.code")}:
                  </span>
                  <span>{item?.code}</span>
                </div>
              </div>
            </div>
            <div className="unit-info my-3">
              <h6 className="text-capitalize unit-title m-0">
                {lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[1]?.title_en
                    : "...."
                  : item?.data_trans
                  ? item?.data_trans[0]?.title_ar
                  : "...."}
              </h6>
              <p className="location text-capitalize m-0">
                <span>
                  <i className="fa fa-map-marker"></i>
                </span>
                <span className="mx-2">{item?.location}</span>
              </p>
            </div>
            <div className="unit-properties d-none d-md-block">
              <ul className="list-row m-0">
                <li className="list-row-item mx-2">
                  <span>
                    <i className="fa fa-bath color-primary"></i>{" "}
                    {item?.bathroom}
                  </span>
                </li>
                <li className="list-row-item mx-2">
                  <span>
                    <i className="fa fa-inbox color-primary"></i> {item?.rooms}
                  </span>
                </li>
                <li className="list-row-item mx-2">
                  <span>
                    <i className="fa fa-map color-primary"></i> {item?.size}
                  </span>
                </li>
              </ul>
            </div>
            <div className="owner-info">
              <ul className="list-row">
                <li className="list-row-item w-100">
                  <p className="my-1 mx-0 p-0 fw-medium text-secondary h-22px ellipses">
                    {item?.installments?.length > 0 &&
                      `${t("utility.cards.card.deposit")} ${formatNumber(
                        item?.installments?.[0].deposit
                      )} / ${item?.installments?.[0].years} ${t(
                        "utility.cards.card.years"
                      )} `}
                  </p>
                  <h6 className="unit-price m-0 fw-bold p-2 radius-5 bg-light-gray">{`${t(
                    "utility.cards.card.EGP"
                  )} ${formatNumber(item?.price)}`}</h6>
                </li>
              </ul>
            </div>
          </div>
          <div className="card-footer d-flex align-items-center justify-content-between flex-wrap">
            <ul className="list-row my-1 contacts">
              <li className="list-row-item">
                <a
                  href={`https://wa.me/+20${item?.user?.phone}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-social whatsapp"
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
              </li>
              <li className="list-row-item">
                <a
                  href={`tel:+20${item?.user?.phone}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-social twitter"
                >
                  <i className="fa fa-phone"></i>
                </a>
              </li>
              <li className="list-row-item">
                <button
                  className="btn-social share"
                  onClick={() => handleChangeActive()}
                >
                  <i className="fa fa-share"></i>
                </button>
              </li>
            </ul>
            <div className="analysis-wrapper">
              <div
                className="wishlist"
                onClick={() => handleClickWishlist(item?.id)}
              >
                <span className="mx-1">
                  <img
                    src={isFav === true ? redHeart : blackHeart}
                    alt="like-img"
                    loading="lazy"
                  />
                </span>
                <span>{wishlistCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {active && (
        <ShareCard
          handleChange={handleChangeActive}
          shareUrl={`${process.env.REACT_APP_URL}single-details/:${item?.id}`}
        />
      )}
    </motion.div>
  );
};

export default Card;

import React from "react";
import "./Carousel.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import GetAllWishlistHook from "../../../CustomHooks/Wishlist/GetAllWishlistHook";
import { useTranslation } from "react-i18next";
import Card from "../Cards/Card/Card";
const Carousel = ({ units }) => {
  const [, , wishlistIds] = GetAllWishlistHook();
  const { t, i18n } = useTranslation();
  const langDirection = i18n.dir();
  return (
    <Swiper
      spaceBetween={10}
      navigation={true}
      style={{
        "--swiper-pagination-bullet-width": "10px",
        "--swiper-pagination-bullet-height": "10px",
        "--swiper-pagination-bottom": "-4px",
        "--swiper-pagination-color": process.env.REACT_APP_PRIMARY_COLOR,
        "--swiper-navigation-size": "35px",
        "--swiper-navigation-width": "30px",
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        waitForTransition: true,
      }}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        576: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      }}
      modules={[Pagination, Autoplay, Navigation]}
      dir={!langDirection ? "rtl" : "ltr"}
      key={langDirection}
      className="mySwiper3 similar-properties-swiper"
    >
      {units?.map((item) => {
        return (
          <SwiperSlide key={item?.id}>
            <Card item={item} favItem={wishlistIds} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousel;

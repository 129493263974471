import React from "react";
import "./SingleDetailsPage.css";
import bigImg from "../../../images/bg.jpg";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import SingleDetailsSidebar from "../../../components/SingleDetails/SingleDetailsSidebar/SingleDetailsSidebar";
import SingleDetailDesc from "../../../components/SingleDetails/Features/SingleDetailDesc/SingleDetailDesc";
import SingleDetailInfo from "../../../components/SingleDetails/Features/SingleDetailInfo/SingleDetailInfo";
import SingleDetailAmenities from "../../../components/SingleDetails/Features/SingleDetailAmenities/SingleDetailAmenities";
import SingleDetailVideo from "../../../components/SingleDetails/Features/SingleDetailVideo/SingleDetailVideo";
import SimiliarProperties from "../../../components/SingleDetails/SimiliarProperties/SimiliarProperties";
import { useParams } from "react-router-dom";
import GetUnitDetailsHook from "../../../CustomHooks/UnitsHook/GetUnitDetailsHook";
import HomeSectionSkeleton from "../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import DetailSkeleton from "../../../components/SingleDetails/DetailSkeleton/DetailSkeleton";
import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
// import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ImagesSlider from "../../../components/SingleDetails/ImagesSlider/ImagesSlider";

const SingleDetailsPage = () => {
  const { id } = useParams();
  const [t] = useTranslation();
  const [loading, unit_details] = GetUnitDetailsHook(id?.slice(1, id.length));
  const lang = Cookies.get("i18next");

  return (
    <div className="single-details-page page">
      <CustomHelmet
        title={t("singleDetailsPage.singleDetailsInfo.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader
        breadcrumbTitle={"Single Property"}
        listsActive={"listsActive"}
      />
      {/* <BreadcrumbSection title={t("singleDetailsPage.breadcrumbTitle")} /> */}
      <section className="single__Detail m-0 pt-0 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {loading === false ? (
                <>
                  <div className="details-head">
                    {unit_details?.images?.length > 0 ? (
                      <ImagesSlider items={unit_details.images} />
                    ) : (
                      <img
                        src={unit_details?.cover_image}
                        alt="offer-img"
                        loading="lazy"
                        className="w-100 radius-10 h-500px"
                      />
                    )}
                    <div className="p-2 radius-10 border border-2 txt-start my-3">
                      <h5 className="fw-bold m-0">
                        {lang === "en"
                          ? unit_details?.data_trans
                            ? unit_details?.data_trans[0]?.title_ar
                            : null
                          : unit_details?.data_trans
                          ? unit_details?.data_trans[1]?.title_en
                          : null}
                      </h5>
                      <p className="location text-capitalize my-2 text-secondary fs-16 fw-medium">
                        <span className=" text-secondary">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        <span className="mx-2">{unit_details?.location}</span>
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <HomeSectionSkeleton />
                </>
              )}
              {loading === false ? (
                <>
                  <SingleDetailInfo unitDetails={unit_details} />
                  <SingleDetailDesc unitDetails={unit_details} />
                  {unit_details?.property?.length > 0 && (
                    <SingleDetailAmenities unitDetails={unit_details} />
                  )}
                  {unit_details?.video && (
                    <SingleDetailVideo
                      bigImg={bigImg}
                      unitDetails={unit_details}
                    />
                  )}
                </>
              ) : (
                <>
                  <DetailSkeleton />
                  <DetailSkeleton />
                  <DetailSkeleton />
                </>
              )}
            </div>
            <div className="col-lg-4 mb-5">
              {loading === false ? (
                <SingleDetailsSidebar item={unit_details} />
              ) : null}
            </div>
          </div>
          <div className="row my-5">
            <div className="col-lg-12">
              {unit_details?.category?.id && unit_details?.price && (
                <SimiliarProperties
                  id={unit_details?.category?.id}
                  price={unit_details?.price}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SingleDetailsPage;

import React from "react";
import NotFound from "../Utility/NotFound/NotFound";
import CustomPagination from "../Utility/CustomPagination/CustomPagination";
import JobCard from "./JobCard";
import GetAllJobsHook from "../../CustomHooks/Jobs/GetAllJobsHook";
import HomeSectionSkeleton from "../Home/HomeSectionSkeleton/HomeSectionSkeleton";

const JobsListContent = () => {
  const [loading, jobs, jobsCount, meta, onPressPaginate] = GetAllJobsHook();

  return (
    <div className="list-content min-h-100">
      <div className="row units-wrapper">
        {loading === false ? (
          jobsCount > 0 ? (
            jobs?.map((item) => (
              <div className="col-md-6 col-xl-4" key={item?.id}>
                <JobCard item={item} />
              </div>
            ))
          ) : (
            <NotFound />
          )
        ) : (
          <>
            <HomeSectionSkeleton />
            <HomeSectionSkeleton />
          </>
        )}
      </div>
      <div className="pagination-content d-flex align-items-center justify-content-center">
        <CustomPagination
          totalPages={meta?.last_page}
          currentPage={meta?.current_page}
          onChange={onPressPaginate}
        />
      </div>
    </div>
  );
};

export default JobsListContent;

import {
  faFacebookF,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ShareComponent from "../../Utility/Share/ShareComponent/ShareComponent";
import logo from "../../../images/logo.png";
import OurInfoHook from "../../../CustomHooks/OurSettings/OurInfo/OurInfoHook";

const ContactButtons = ({ whatsapp, phone, email, instagram, facebook }) => {
  const [t] = useTranslation();
  const [active, setActive] = useState(false);
  const [data] = OurInfoHook();
  const handleChangeActive = () => {
    setActive(!active);
  };
  return (
    <div className="contacts text-center">
      <div className="border border-2 p-5 radius-10 overflow-hidden">
        <img src={logo} loading="lazy" alt="logo" className="w-150px" />
      </div>
      <div className="p-3 my-2">
        <div className="d-flex align-items-center gap-2 flex-wrap">
          <a
            href={facebook || data?.facebook}
            target="_blank"
            rel="noreferrer"
            className="facebook border-0 text-white py-2 px-3 radius-10 fs-20 d-flex align-items-center flex-nowrap flex-shrink-1"
          >
            <span className="mx-2">Facebook</span>
            <span>
              <FontAwesomeIcon icon={faFacebookF} />
            </span>
          </a>
          <a
            href={instagram || data?.instagram}
            target="_blank"
            rel="noreferrer"
            style={{
              background:
                "linear-gradient(to right, #EAA849, #BD457D, #A24886)",
            }}
            className="instagram border-0 text-white py-2 px-3 radius-10 fs-20 d-flex align-items-center flex-nowrap flex-shrink-1"
          >
            <span className="mx-2">Instagram</span>
            <span>
              <FontAwesomeIcon icon={faInstagram} />
            </span>
          </a>
        </div>
        <ul className="list-row my-1 contacts flex-wrap">
          <li className="list-row-item px-3 py-1 radius-5 border border-1 text-nowrap d-flex align-items-center my-1">
            <a
              href={`tel:+20${phone || data?.phone}`}
              target="_blank"
              rel="noreferrer"
              className="btn-social twitter radius-50 overflow-hidden"
            >
              <FontAwesomeIcon icon={faPhone} />
            </a>
            <span className="mx-2">
              {t("singleDetailsPage.singleDetailsSidebar.phone")}
            </span>
          </li>
          <li className="list-row-item px-3 py-1 radius-5 border border-1 my-1">
            <a
              href={`https://wa.me/+20${phone || data?.phone}`}
              target="_blank"
              rel="noreferrer"
              className="btn-social whatsapp radius-50 overflow-hidden"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </li>
          <li className="list-row-item px-3 py-1 radius-5 border border-1 my-1">
            <a
              href={`mailto:${email || data?.email}`}
              className="btn-social email-btn bg-youtube  radius-50 overflow-hidden"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </li>
          <li className="list-row-item px-4 py-1 radius-5 border border-1 my-1">
            <span
              className="fs-20 pointer"
              onClick={() => handleChangeActive()}
            >
              <FontAwesomeIcon icon={faShareNodes} />
            </span>
          </li>
          {active && (
            <ShareComponent
              handleChange={handleChangeActive}
              shareUrl={window.location.href}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

export default ContactButtons;

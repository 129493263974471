import React from "react";
import NotFound from "../../../../components/Utility/NotFound/NotFound";
import Card from "../../../../components/Utility/Cards/Card/Card";

const MallUnits = ({ units }) => {
  return (
    <div className="mall-units-content">
      <div className="row units-wrapper">
        {units?.length > 0 ? (
          units?.map((item) => (
            <div className="col-sm-6" key={item?.id}>
              <Card item={item} />
            </div>
          ))
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};

export default MallUnits;

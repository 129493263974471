import React from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { ReadMore } from "../../../UI/ReadMore";
const SingleDetailDesc = ({ unitDetails }) => {
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();
  return (
    <div className="single__detail-desc">
      <h5 className="text-capitalize detail-heading fw-bold fs-22 color-primary">
        {t("singleDetailsPage.singleDetailsDes.description")}
      </h5>
      <div className="show_more">
        <ReadMore
          amountOfWords={100}
          id={"read-more-text"}
          content={
            lang === "en"
              ? unitDetails?.data_trans?.[1]?.description_en ||
                unitDetails?.data_trans?.[0]?.description_en
              : unitDetails?.data_trans?.[0]?.description_ar ||
                unitDetails?.data_trans?.[1]?.description_ar
          }
        />
      </div>
    </div>
  );
};

export default SingleDetailDesc;

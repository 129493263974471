import React from "react";
import { useTranslation } from "react-i18next";
import WantToSell from "../../components/Home/WantToSell/WantToSell";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import DisplayWordpadContent from "../../components/Utility/DisplayWordpadContent/DisplayWordpadContent";
import ContactButtons from "../../components/UI/contact-btns/ContactButtons";
import { useParams } from "react-router-dom";
import HomeSectionSkeleton from "../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import Cookies from "js-cookie";
import GetLandDetailsHook from "../../CustomHooks/Lands/GetLandDetailsHook";
import anonymous from "../../images/anonymous-image.png";
import FormatNumberHook from "../../CustomHooks/FormatNumber/FormatNumberHook";
const LandDetailsPage = () => {
  const { t } = useTranslation();
  const lang = Cookies.get("i18next");
  const { id } = useParams();
  const [loading, landDetails] = GetLandDetailsHook(id);
  const [formatNumber] = FormatNumberHook();

  return (
    <div className="listing-page page">
      <CustomHelmet
        title={t("land_details.breadcrumbTitle")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader landsActive={"landsActive"} />
      {/* <BreadcrumbSection title={t("land_details.breadcrumbTitle")} /> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {loading === false ? (
                <>
                  <div className="details-head">
                    <img
                      src={landDetails?.image || anonymous}
                      alt="land-img"
                      loading="lazy"
                      className="w-100 radius-10 h-500px"
                    />
                    <div className="p-2 radius-10 border border-2 txt-start my-3">
                      <h5 className="fw-bold m-0">
                        {lang === "en"
                          ? landDetails?.data_trans?.[1]?.title_en
                          : landDetails?.data_trans?.[0]?.title_ar}
                      </h5>
                      <p className="location text-capitalize my-2 text-secondary fs-16 fw-medium">
                        <span className=" text-secondary">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        <span className="mx-2">{landDetails?.address}</span>
                      </p>
                    </div>
                  </div>
                  <div className="details-body txt-start p-4">
                    <h5 className="fw-bold my-3 text-capitalize">
                      {t("land_details.property_details")}{" "}
                    </h5>
                    {landDetails?.data_trans ? (
                      lang === "en" ? (
                        <DisplayWordpadContent
                          content={landDetails?.data_trans?.[1]?.description_en}
                        />
                      ) : (
                        <DisplayWordpadContent
                          content={landDetails?.data_trans?.[0]?.description_ar}
                        />
                      )
                    ) : null}
                  </div>
                </>
              ) : (
                <HomeSectionSkeleton />
              )}
            </div>
            <div className="col-lg-4">
              <div className="price p-4 bg-sec-2 radius-10 fw-medium text-white my-2">
                <h3>
                  {formatNumber(landDetails?.price)} {t("land_details.pound")}
                </h3>
              </div>
              <ContactButtons
                whatsapp={landDetails?.user?.phone}
                phone={landDetails?.user?.phone}
              />
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default LandDetailsPage;

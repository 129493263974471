import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPremiumUnits } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";

const GetPremiumUnitsHook = () => {
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState([]);
  const [units, setUnits] = useState([]);
  const [unitsCount, setUnitsCount] = useState(1);
  const firstRender = useRef(true);
  const res = useSelector(
    (state) => state.rootReducer.unitsReducer.premiumUnits
  );
  const dispatch = useDispatch();
  // local storage
  let savedSearchWord = localStorage.getItem("searchWord") || "";
  let savedCategoryId = localStorage.getItem("category_id") || "";
  let savedRooms = localStorage.getItem("rooms") || "";
  let savedBathRooms = localStorage.getItem("bathRooms") || "";
  let savedType = localStorage.getItem("type") || "";
  let savedMinPrice = localStorage.getItem("minPrice") || "";
  let savedMaxPrice = localStorage.getItem("maxPrice") || "";
  let savedSpace = localStorage.getItem("space") || "";
  let savedSort = localStorage.getItem("sort") || "";
  let savedPropertyId = localStorage.getItem("propertyId") || "";
  let savedMainCategory = localStorage.getItem("main_category") || "";
  // let savedFinishing = localStorage.getItem("finishing") || "";
  // let savedFurnished = localStorage.getItem("furnished") || "";
  let savedGateId = localStorage.getItem("gate_id") || "";
  let savedDistrictId = localStorage.getItem("district_id") || "";

  const getData = async (queryString) => {
    setLoading(true);
    await dispatch(GetPremiumUnits(queryString));
    setLoading(false);
  };

  useEffect(() => {
    if (firstRender.current === true) {
      getData(`filter_search=${savedSearchWord}&price_min=${savedMinPrice}&price_max=${savedMaxPrice}
      &space=${savedSpace}&type=${savedType}&rooms=${savedRooms}&bathroom=${savedBathRooms}
      &property_id=${savedPropertyId}&category_id=${savedCategoryId}&sort=${savedSort}
      &main_category=${savedMainCategory}&gates_id=${savedGateId}&district_id=${savedDistrictId}`);
      firstRender.current = false;
    }
  }, []);

  //on press function for pagination
  const onPressPaginate = (page) => {
    getData(`filter_search=${savedSearchWord}&price_min=${savedMinPrice}&price_max=${savedMaxPrice}
  &space=${savedSpace}&type=${savedType}&rooms=${savedRooms}&bathroom=${savedBathRooms}&property_id=${savedPropertyId}
  &category_id=${savedCategoryId}&page=${page}&sort=${savedSort}&gate_id=${savedGateId}&main_category=${savedMainCategory}
  &district_id=${savedDistrictId}`);
  };

  useEffect(() => {
    try {
      if (loading === false) {
        if (res?.status === 200) {
          setUnits(res?.data?.data);
          setUnitsCount(res?.data?.data?.length || 0);
          setMeta(res?.data?.meta);
        } else {
          setUnits([]);
          setUnitsCount(0);
          setMeta(null);
        }
      }
    } catch (e) {}
  }, [loading, res]);

  return [loading, units, unitsCount, meta, getData, onPressPaginate];
};

export default GetPremiumUnitsHook;

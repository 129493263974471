import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import DeveloperCard from "../DeveloperCard/DeveloperCard";

const DevelopersCarousel = ({ items, loading }) => {
  const { t, i18n } = useTranslation();
  const langDirection = i18n.dir();
  return (
    <Swiper
      spaceBetween={10}
      // navigation={true}
      style={{
        "--swiper-pagination-bullet-width": "10px",
        "--swiper-pagination-bullet-height": "10px",
        "--swiper-pagination-bottom": "-4px",
        "--swiper-pagination-color": "#f3b01c",
        "--swiper-navigation-size": "35px",
        "--swiper-navigation-width": "30px",
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        waitForTransition: true,
      }}
      // pagination={{
      //   clickable: true,
      // }}
      breakpoints={{
        576: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        992: {
          slidesPerView: 6,
          spaceBetween: 10,
        },
      }}
      modules={[Pagination, Autoplay, Navigation]}
      dir={!langDirection ? "rtl" : "ltr"}
      key={langDirection}
      className="developers-swiper similar-properties-swiper"
    >
      {loading === false
        ? items?.map((item) => {
            return (
              <SwiperSlide key={item?.id}>
                <DeveloperCard item={item} />
              </SwiperSlide>
            );
          })
        : null}
    </Swiper>
  );
};

export default DevelopersCarousel;

import React from "react";
import "./FilterSidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { changeFilterSidebarActive } from "../../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import Cookies from "js-cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FilterItems from "./FilterItems/FilterItems";
import { faX } from "@fortawesome/free-solid-svg-icons";

const FilterSidebar = ({ unitsType }) => {
  const lang = Cookies.get("i18next");
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(changeFilterSidebarActive());
  };
  const handleClick1 = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(changeFilterSidebarActive());
    }
  };

  const active = useSelector(
    (state) => state.rootReducer.sidebarReducer.filterSidebarActive
  );

  return (
    <div
      className={`filter-sidebar fixed-sidebar transition ${
        lang === "ar" ? "fixed-sidebar-ar" : "fixed-sidebar-en"
      } ${active === true ? "active" : ""}`}
      onClick={(e) => handleClick1(e)}
    >
      <div className="sidebar-content" onClick={(e) => handleClick1(e)}>
        <div className="sidebar-head p-3 bg-sec d-flex align-items-center justify-content-between">
          <h4 className="fw-normal  text-white">فلتر بحث</h4>
          <span
            className="bg-pri w-35px h-35px radius-50 d-flex align-items-center justify-content-center pointer text-white fw-bold fs-20 "
            onClick={() => handleClick()}
          >
            <FontAwesomeIcon icon={faX} />
          </span>
        </div>
        <div className="sidebar-menu-wrap overflow-y-auto p-0">
          <FilterItems unitsType={unitsType} />
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;

import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import MallsListContent from "../../../components/Malls/MallsListContent/MallsListContent";
const MallsPage = () => {
  const { t } = useTranslation();
  return (
    <div className="malls-page page">
      <CustomHelmet
        title={t("mallsPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader mallsActive={"mallsActive"} />
      {/* <BreadcrumbSection title={t("mallsPage.title")} /> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MallsListContent />
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default MallsPage;

import React from "react";
import Landing from "../../components/GeneralSections/Landing/Landing";
import HomeSectionSkeleton from "../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import { useTranslation } from "react-i18next";
import CategoriesAnalysis from "../../components/Home/CategoriesAnalysis/CategoriesAnalysis.js";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet.js";
// import OurTeam from "../../components/Home/OurTeam/OurTeam.js";
import Developers from "../../components/Home/Developers/Developers.js";
// lazy loading components
const Compounds = React.lazy(() =>
  import("../../components/Home/Compounds/Compounds.js")
);
const Malls = React.lazy(() => import("../../components/Home/Malls/Malls.js"));
const HomeServices = React.lazy(() =>
  import("../../components/Home/HomeServices/HomeServices.js")
);
const Cta = React.lazy(() => import("../../components/Home/Cta/Cta"));
const WantToSell = React.lazy(() =>
  import("../../components/Home/WantToSell/WantToSell.js")
);
const PremiumUnits = React.lazy(() =>
  import("../../components/Home/PremiumUnits/PremiumUnits.js")
);
const MobileAppSection = React.lazy(() =>
  import("../../components/Home/MobileAppSection/MobileAppSection.js")
);
const OurPartners = React.lazy(() =>
  import("../../components/Home/OurPartners/all.js")
);
// const Clients = React.lazy(()=>import('../../components/Home/FeaturedClients/FeaturedClients'))
// const HomeAbout = React.lazy(()=>import('../../components/Home/HomeAbout/HomeAbout'))

const HomePage = () => {
  const [t] = useTranslation();
  return (
    <div className="home-page">
      <CustomHelmet
        title={t("homePage.meta.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <Landing />

      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <OurPartners />
      </React.Suspense>
      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <Compounds />
      </React.Suspense>

      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <Cta />
      </React.Suspense>

      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <PremiumUnits />
      </React.Suspense>

      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <Malls />
      </React.Suspense>

      <CategoriesAnalysis />

      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <HomeServices />
      </React.Suspense>

      <Developers />

      {/* <React.Suspense fallback={<HomeSectionSkeleton />}>
        <OurTeam />
      </React.Suspense> */}

      {/* <React.Suspense fallback={<HomeSectionSkeleton/>}>
        <HomeAbout />
        </React.Suspense> */}

      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <WantToSell />
      </React.Suspense>

      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <MobileAppSection />
      </React.Suspense>
    </div>
  );
};

export default HomePage;

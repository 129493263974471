import React, { useState } from "react";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import { useTranslation } from "react-i18next";
import WantToSell from "../../components/Home/WantToSell/WantToSell";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import FormatNumberHook from "../../CustomHooks/FormatNumber/FormatNumberHook";
import AddSearchParamsHook from "../../CustomHooks/helpers/add_search_params_hook";
import BtnComponent from "../../components/Utility/BtnComponent/BtnComponent";
import { useDispatch } from "react-redux";
import { GetAllLands } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";
import LandsListContent from "../../components/Lands/LandsListContent";

const LandsPage = () => {
  const { t } = useTranslation();
  const [formatNumber] = FormatNumberHook();
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  // start for range slider
  const [value, setValue] = useState([0, 1000]);
  // handle change price
  const [add_search_params, searchParams] = AddSearchParamsHook();
  const pageUrl = searchParams.get("page") || "";

  const onChangeMinPrice = (e) => {
    setMinPrice(e);
  };
  const onChangeMaxPrice = (e) => {
    setMaxPrice(e);
  };

  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue);
  };

  // get the data from the server
  const dispatch = useDispatch();
  const getFilterData = async (queryString) => {
    await dispatch(GetAllLands(queryString));
  };

  const onSearch = () => {
    getFilterData(
      `price_min=${minPrice}&price_max=${maxPrice}&page=${pageUrl}`
    );
  };

  return (
    <div className="listing-page page">
      <CustomHelmet
        title={t("lands_page.breadcrumbTitle")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader landsActive={"landsActive"} />
      {/* <BreadcrumbSection title={t("lands_page.breadcrumbTitle")} /> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="lands-filter p-3 txt-start rounded bg-light">
                <div className="form-group price-range-slider fit-flex flex-wrap">
                  <div className="border border-1 rounded flex-fill mx-3 px-3">
                    <Box sx={{ width: "100%" }}>
                      <Slider
                        value={value}
                        onChange={handleChange}
                        min={0}
                        max={20000000}
                        step={100000}
                      />
                      <Typography id="track-inverted-slider" gutterBottom>
                        <span className="parent-span d-flex align-items-center justify-content-between ">
                          <span className="value-1">{`${t(
                            "listingPage.listingSidebar.EGP"
                          )} ${formatNumber(maxPrice)}`}</span>
                          <span className="value-0 ">{`${t(
                            "listingPage.listingSidebar.EGP"
                          )} ${formatNumber(minPrice)}`}</span>
                        </span>
                      </Typography>
                    </Box>
                  </div>
                  <BtnComponent
                    value={"search"}
                    st={"wd-fit px-5 my-4"}
                    onClick={onSearch}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <LandsListContent />
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default LandsPage;

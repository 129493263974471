import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import DeveloperUnits from "../../../components/Developers/DeveloperUnits/DeveloperUnits";
import GetDeveloperDetailsHook from "../../../CustomHooks/Developers/GetDeveloperDetailsHook";
import { useParams } from "react-router-dom";
import ContactButtons from "../../../components/UI/contact-btns/ContactButtons";
import HomeSectionSkeleton from "../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import { ReadMore } from "../../../components/UI/ReadMore";
const DeveloperDetailsPage = () => {
  const { id } = useParams();
  const [t] = useTranslation();
  const [loading, developerDetails] = GetDeveloperDetailsHook(
    id?.slice(1, id.length)
  );
  return (
    <div className="developers-page page">
      <CustomHelmet
        title={t("developerDetailsPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader developersActive={"developersActive"} />
      {/* <BreadcrumbSection title={t("developerDetailsPage.title")} /> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <h4 className="fw-bold txt-start my-5 fs-20">
                {t("developerDetailsPage.about")}
              </h4>
              {loading === false ? (
                <div className="developer-info my-3 txt-start">
                  <ReadMore content={developerDetails?.description} />
                </div>
              ) : (
                <div className="my-4">
                  <HomeSectionSkeleton />
                  <HomeSectionSkeleton />
                </div>
              )}
            </div>
            <div className="col-lg-4 d-none d-xl-block">
              <ContactButtons
                phone={developerDetails?.phone}
                whatsapp={developerDetails?.phone}
                facebook={developerDetails?.facebook}
                instagram={developerDetails?.instagram}
                email={developerDetails?.email}
              />
            </div>
            <div className="col-12">
              {developerDetails?.projects?.length > 0 && (
                <div className="developer-units my-5">
                  <h3 className="fw-bold txt-start my-5 fs-20">
                    {t("developerDetailsPage.important_projects")}
                  </h3>
                  <DeveloperUnits compounds={developerDetails?.projects} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
    </div>
  );
};

export default DeveloperDetailsPage;
